<template>
    <div class="nh-pop">
        <div>
            <div @click="popComShow">
                点击查看商品详情
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data(){
        return{

        }
    },
    created(){

    },
    methods:{
        popComShow(){
            
        }
    }
}
</script>

<style>
    .nh-pop{
        position: fixed;
        right: 10%;
        top: 0;
        width: 40%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .nh-pop > div{
        width: 100%;
        height: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .nh-pop > div > div{
        width: 180px;
        height: 45px;
        background: rgba(0,0,0,0.6);
        line-height: 45px;
        color: white;
        text-align: center;
        border-radius: 15px;
    }

</style>